import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useReducer,
} from "react"
import {
  Card,
  CardActions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  Modal,
} from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import FormLabel from "@material-ui/core/FormLabel"
import Checkbox from "@material-ui/core/Checkbox"
import DropDownMenu from "../../components/dropDownMenu"
import Button from "@material-ui/core/Button"
import Database from "../../utils/database"
import AssetDatabase from "../../utils/assetDatabase"
import { withRouter } from "react-router"
import ComponentPopupList from "./ComponentPopupList"
import ComponentNotes from "./ComponentNotes"
import Typography from "@material-ui/core/Typography"
import ComponentSettings from "./settings/ComponentSettings"
import TourPlayer from "./tourPlayer/TourPlayer"
import ReactCursorPosition from "react-cursor-position"
import WarningComponentPopup from "./settings/WarningComponentPopup"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom"
import TourSectionManager from "../tourManagerComponents/TourSectionManager"
import SnapShotManager from "./tourPlayer/InteractiveLayer/blocks/SnapShotManager"
import ComponentSelector from "./tourPlayer/InteractiveLayer/blocks/ComponentSelector"
import AssetsViewer from "./assetManagement/AssetsViewer"
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline"
import VisibilityIcon from "@material-ui/icons/Visibility"
import BuildIcon from "@material-ui/icons/Build"
import WarningIcon from "@material-ui/icons/Warning"
import NoteAdd from "@material-ui/icons/NoteAdd"
import Slide from "@material-ui/core/Slide"

import GridList from "@material-ui/core/GridList"
import GridListTile from "@material-ui/core/GridListTile"
import GridListTileBar from "@material-ui/core/GridListTileBar"
import CommentsManager from "../commentsComponets/CommentsManager"
import { ItemSelectContext } from "../../Private/tourcreator"
import UserPermissions from "../../utils/userPermissions"
import { GetElementsToRender, GettingHelpURL } from "./ComponentController"
import HelpButton from "../HelpButton"
import * as htmlToImage from "html-to-image"
import ReactDOMServer from "react-dom/server"
import ReactDOM from "react-dom"
import TourExporter from "../../utils/buildTour"
import { CardContent } from "semantic-ui-react"

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#B9D949",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  gridList: {
    width: "100%",
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
}))

export const ItemContext = React.createContext({
  item: {},
  unSavedItem: null,
  siblings: [],
  previewMode: true,
  previousItem: {},
  previousItems: [],
  updateItem: undefined,
  changesDetected: false,
  buttonPressed: null,
  tourId: undefined,
  updatePreviewMode: () => {},
  tourSectionManager: false,
  openTourSectionManager: () => {},
  openSnapShot: false,
  ComponentSelectorProperties: {},
  setComponentSelector: () => {},
  openingSnapShot: () => {},
  updateComponent: () => {},
  updateItem: () => {},
  openComponentSelector: false,
  openAssetBrower: false,
  assetRequirements: undefined,
  preSelectedAsset: undefined,
  setImg: null,
  handleAssetsViewerOpen: (
    assetRequirements,
    preSelectedAsset,
    setImg,
    previewMode
  ) => {},
  openingComponentSelector: () => {},
  updateTourPlayer: () => {},
  TourPlayer: {},
  checkIfCodeIsUsed: (code) => {},
})
const saveAs = (blob, fileName) => {
  var elem = window.document.createElement("a")
  elem.href = blob
  elem.download = fileName
  elem.style = "display:none;"
  ;(document.body || document.documentElement).appendChild(elem)
  if (typeof elem.click === "function") {
    elem.click()
  } else {
    elem.target = "_blank"
    elem.dispatchEvent(
      new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      })
    )
  }
  URL.revokeObjectURL(elem.href)
  elem.remove()
}

const ComponentLoader = (props) => {
  const classes = useStyles()
  const itemSelectContext = useContext(ItemSelectContext)
  const { closePreviewMode } = itemSelectContext
  //const [openTourSectionManager, setOpenTourSectionManager] = useState(false);
  console.log("Component details item -> ", props.state)
  //const [openAssetBrower, setOpenAssetBrower] = React.useState(false);
  //const {item,siblings} = props.state;
  const [tempItem, setTempItem] = React.useState(props.state.item)
  const [item, setItem] = React.useState(props.state.item)
  const [siblings, setSiblings] = React.useState(props.state.siblings)
  const [warnings, setWarnings] = useState([])
  const [numberOfIssues, setNumberOfIssues] = React.useState(0)
  const initialQuizState = {
    score: 0,
    scoreEvents: [],
    projectDeviceSettings: item.projectDetails?.data?.projectDeviceSettings,
    previousCodes: [],
    historyCodes: [],
  }
  const quizReducer = (state, action) => {
    switch (action.type) {
      case "answer":
        // payload is going to be a code
        let checkCodeForAnswer
        let checkForReset
        if (state.projectDeviceSettings) {
          checkCodeForAnswer = state.projectDeviceSettings.find(
            (obj) =>
              obj.value === action.payload &&
              obj.name.toLowerCase().indexOf("score_") >= 0 &&
              obj.name.toLowerCase() !== "score_container"
          )
          checkForReset = state.projectDeviceSettings.find((obj) => {
            let resetScore = false
            if (obj.name.toLowerCase() === "score_condition") {
              let codeLines = obj.value.split("\n")
              console.log("codeLines:", codeLines)
              if (codeLines[1] && codeLines[1] === action.payload) {
                resetScore = true
              }
            }
            console.log("resetScore", resetScore)
            return resetScore
          })
        }

        state.historyCodes.push(action.payload)
        if (!state.previousCodes.includes(action.payload)) {
          state.previousCodes.push(action.payload)
        }
        console.log("checkCodeForAnswer:", checkCodeForAnswer)
        if (checkForReset) {
          console.log("checkCodeForAnswer:", " resetting answer")
          return { ...state, score: 0, scoreEvents: [] }
        } else if (
          checkCodeForAnswer &&
          !state.scoreEvents.includes(action.payload)
        ) {
          state.scoreEvents.push(action.payload)
          console.log(
            "checkCodeForAnswer:",
            "answer will be added",
            action.payload,
            state
          )
          return {
            ...state,
            score: state.score++,
            scoreEvents: state.scoreEvents,
          }
        } else if (
          checkCodeForAnswer &&
          state.scoreEvents.includes(action.payload)
        ) {
          console.log(
            "checkCodeForAnswer:",
            "answer found but already added",
            action.payload,
            state
          )
          return { ...state }
        } else {
          return { ...state }
        }
      //return {count: state.count + 1};
      // case 'decrement':
      //   return {count: state.count - 1};
      default:
        throw new Error()
    }
  }

  const [quiz, quizDispatch] = useReducer(quizReducer, initialQuizState)
  console.log("quiz ", quiz)
  useEffect(() => {
    setTempItem(props.state.item)
    setItem(props.state.item)
    setSiblings(props.state.siblings)
    //updateItem(props.state.item,)
  }, [props.state.item, props.state.siblings])

  const [previewMode, setPreviewMode] = React.useState(
    props.preview ? true : false
  )
  const [tempPreviousItem, setTempPreviousItem] = React.useState(null)

  const [openWarningSave, setOpenWarningSave] = React.useState(false)
  const [openSidePanel, setOpenSidePanel] = React.useState(false)
  const [openNotesPanel, setOpenNotesPanel] = React.useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [openExplore, setOpenExplore] = React.useState(false)
  const [commentManager, setCommentManager] = React.useState(null)
  const [htmlToImageDiv, setHtmlToImage] = React.useState(null)

  const checkQuizCode = (item, siblings) => {
    let newItem = item
    let projectDeviceSettings = item.projectDetails?.data?.projectDeviceSettings
    if (quiz.score !== 0 && projectDeviceSettings) {
      let scoreConditions = projectDeviceSettings.find(
        (obj) => obj.name.toLowerCase() === "score_condition"
      )
      if (scoreConditions) {
        let scoreConditionsElements = scoreConditions.value.split("\n")
        console.log(
          "code check for quiz: ",
          scoreConditionsElements[0],
          item.data.code
        )
        if (scoreConditionsElements[0] === item.data.code) {
          let newCode = scoreConditionsElements[1 + quiz.score]
          console.log(
            "code check for quiz: ",
            "check for new code",
            item.data.code,
            newCode
          )
          let findNewItem = siblings.find((obj) => obj.data.code === newCode)
          if (findNewItem) {
            console.log("code check for quiz: ", "new item", findNewItem)
            newItem = findNewItem
          }
        }
      }
    }
    let scratchSwitchCode = null
    if (projectDeviceSettings) {
      let checkForReset = projectDeviceSettings.find((obj) => {
        let scratchFound = false
        if (obj.name.toLowerCase().indexOf("scratch_") >= 0) {
          let codeLines = obj.value.split("\n")
          if (
            quiz.previousCodes.includes(codeLines[0]) &&
            codeLines[1] === item.data.code
          ) {
            scratchSwitchCode = codeLines[2]
            scratchFound = true
          }
        }
        //console.log('scratchFound',scratchFound,scratchSwitchCode)
        return scratchFound
      })
      if (scratchSwitchCode && checkForReset) {
        let findNewScratchItem = siblings.find(
          (obj) => obj.data.code === scratchSwitchCode
        )
        if (findNewScratchItem) {
          //console.log('code check for scratch: ', 'new item',findNewScratchItem)
          newItem = findNewScratchItem
        }
      }
    }

    return newItem
  }
  //const [openSnapShot, setOpenSnapShot] = React.useState(false);
  const updateItem = (item, from, button = null, currentItem = null) => {
    console.log(
      "update item from component loader preview: ",
      item,
      from,
      button,
      state.previousItems
    )
    if (item === null) {
      window.alert("There is no previous component defined")
      return
    } else if (item === "exit") {
      window.alert("This button will trigger the device to exit the tour")
      return
    } else if (item === "externalLink") {
      window.open(`${button.externalLink}`)
      return
    } else if (item === "externalLinkWithoutButton") {
      // console.log('button, item and Item type :>> ', button, item, currentItem.externalLink);
      window.open(`${currentItem.externalLink}`)
      return
    }
    const previousItem = state.item
    let portrait = true

    let newItem = item
    if (item.data?.code) {
      //console.log('checkCodeForAnswer: update item ', item.data?.code)
      quizDispatch({ type: "answer", payload: item.data?.code })
      newItem = checkQuizCode(item, state.siblings)
    }
    if (newItem.data.orientation !== undefined) {
      if (newItem.data.orientation === "Landscape") {
        portrait = false
      }
    }
    /*if(){

          }*/
    let unSavedItem = null
    if (state.item === null) {
      unSavedItem = JSON.parse(JSON.stringify(state.item))
    } else if (state.item.id !== newItem.id) {
      unSavedItem = JSON.parse(JSON.stringify(newItem))
    } else {
      unSavedItem = state.unSavedItem
    }
    console.log(
      "Change in item:",
      JSON.stringify(unSavedItem.data) !== JSON.stringify(newItem.data),
      unSavedItem
    )
    if (
      JSON.stringify(unSavedItem.data) !== JSON.stringify(newItem.data) &&
      unSavedItem.id === newItem.id
    ) {
      setState((prevState) => {
        return {
          ...prevState,
          //unSavedItem:unSavedItem,
          changesDetected: true,
          updateComponent: updateComponent,
        }
      })
    } else if (unSavedItem.id === newItem.id) {
      setState((prevState) => {
        return {
          ...prevState,
          //unSavedItem:unSavedItem,
          changesDetected: false,
          updateComponent: updateComponent,
        }
      })
    }
    if (
      JSON.stringify(state.unSavedItem.data) !==
        JSON.stringify(state.item.data) &&
      state.item.id !== newItem.id
    ) {
      console.log("requires a save", state.item, state.unSavedItem, newItem)
      setState((prevState) => {
        return {
          ...prevState,
          unSavedItem: state.item,
          changesDetected: false,
          updateComponent: updateComponent,
        }
      })
      updateComponent(state.item)
    }
    if (state.previousItems.length > 1) {
      //newPrevious = state.previousItems[state.previousItems.length-2];
      console.log(
        "update going back to ",
        previousItem,
        newItem,
        state.previousItems[-1] === newItem,
        state.previousItems.lastIndexOf(newItem),
        state.previousItems
      )
    }
    let previousIndex = state.previousItems.lastIndexOf(newItem)
    if (previousIndex >= 0) {
      let newPrevious = null

      console.log("going back to ", previousItem, state.previousItems.length)
      if (state.previousItems.length > 0) {
        newPrevious = state.previousItems[previousIndex - 1]
        console.log("going back to new", newPrevious)
      }
      setState((prevState) => {
        return {
          ...prevState,
          item: newItem,
          unSavedItem: unSavedItem,
          portrait: portrait,
          previousItem: newPrevious,
          previousItems: state.previousItems.splice(
            previousIndex,
            state.previousItems.length - previousIndex
          ),
          buttonPressed: button,
          updateItem: updateItem,
        }
      })
    } else {
      state.previousItems.push(previousItem)
      setState((prevState) => {
        return {
          ...prevState,
          previousItem: previousItem,
          unSavedItem: unSavedItem,
          previousItems: state.previousItems,
          item: newItem,
          portrait: portrait,
          buttonPressed: button,
          updateItem: updateItem,
        }
      })
    }
    // setCommentManager(<CommentsManager key={'item'+state.item.id} getNumberOfIssues = {(number)=>{
    //   setNumberOfIssues(number)
    //   console.log('number of issues',number)
    // }}/>)
  }
  const updatePreviewMode = (previewMode) => {
    setState((prevState) => {
      return { ...prevState, previewMode: previewMode }
    })
  }
  const openTourSectionManager = (tourSectionManager, previewMode) => {
    //console.log('update item from component loader preview: '+previewMode, state.previewMode);
    //setState({...state, tourSectionManager: tourSectionManager, previewMode:previewMode!==undefined?previewMode:state.previewMode})
    //setState({...state, tourSectionManager: tourSectionManager})

    setState((prevState) => {
      return { ...prevState, tourSectionManager: tourSectionManager }
    })
  }
  const openingSnapShot = (openSnapShot, previewMode) => {
    //console.log('update item from component loader preview: '+previewMode, state.previewMode);
    //console.log('running function to open openSnapShot');

    setState((prevState) => {
      return { ...prevState, openSnapShot: openSnapShot }
    })
  }
  const openingComponentSelector = (
    ComponentSelectorProperties,
    onCloseSelector = () => {},
    previewMode
  ) => {
    //console.log('update item from component loader preview: '+previewMode, state.previewMode);
    //console.log('running function to open openSnapShot');

    setState((prevState) => {
      return {
        ...prevState,
        openComponentSelector: true,
        ComponentSelectorProperties: ComponentSelectorProperties,
        onCloseSelector: onCloseSelector,
      }
    })
  }
  const handleSnapShotClose = (openSnapShot, previewMode) => {
    //console.log('update item from component loader preview: '+previewMode, state.previewMode);
    setState((prevState) => {
      return { ...prevState, openSnapShot: false }
    })
  }
  const handleComponentSelectorClose = (itemSelected, previewMode) => {
    //console.log('update item from component loader preview: '+previewMode, state.previewMode);
    state.onCloseSelector(itemSelected)

    setState((prevState) => {
      return { ...prevState, openComponentSelector: false }
    })
  }
  const updateTourPlayer = (width, height) => {
    console.log(
      "updateTourPlayer: ",
      state.TourPlayer.width,
      state.TourPlayer.height
    )
    if (
      state.TourPlayer.width !== width ||
      state.TourPlayer.height !== height
    ) {
      setState((prevState) => {
        return { ...prevState, TourPlayer: { width: width, height: height } }
      })
    }
  }

  const handleAssetsViewerOpen = (
    assetRequirements,
    preSelectedAsset,
    setImg,
    previewMode
  ) => {
    //console.log('update item from component loader preview: '+previewMode, state.previewMode);

    setState((prevState) => {
      return {
        ...prevState,
        openAssetBrower: true,
        assetRequirements: assetRequirements,
        preSelectedAsset: preSelectedAsset,
        setImg: setImg,
      }
    })
  }
  const handleAssetsViewerClose = (src, imgName) => {
    console.log("src,name: ->", src, imgName)

    setState((prevState) => {
      return {
        ...prevState,
        openAssetBrower: false,
        assetRequirements: undefined,
        setImg: null,
        preSelectedAsset: undefined,
      }
    })
  }
  let portrait = true
  if (tempItem.data.orientation !== undefined) {
    if (tempItem.data.orientation === "Landscape") {
      portrait = false
    }
  }

  const checkIfCodeIsUsed = (code, type) => {
    let alreadyUsed = false

    siblings.forEach((component) => {
      const { data } = component.tourDetails
      if (type !== "primary" || state.item.id !== component.id) {
        // can be same component, but not primary. If different component, then checks primary code
        if (component.data.code === code && code !== undefined && code !== "") {
          alreadyUsed = true
        }
        if (data?.tourKeypadBck && data?.tourKeypadEnable && code === "6666") {
          alreadyUsed = true
        }
      }

      if (component.data.advancedSettings.secondaryCodes) {
        let codes = component.data.advancedSettings.secondaryCodes
        console.log("secondaryCodes: ", codes)
        for (const secondaryCode in codes) {
          if (codes.hasOwnProperty(secondaryCode)) {
            if (type !== secondaryCode || state.item.id !== component.id) {
              //
              const secondary = codes[secondaryCode]
              if (secondary === code && code !== undefined && code !== "") {
                console.log("secondaryCode alreadyUsed")
                alreadyUsed = true
              }
              if (
                data?.tourKeypadBck &&
                data?.tourKeypadEnable &&
                code === "6666"
              ) {
                alreadyUsed = true
              }
            }
          }
        }
      }
    })
    return alreadyUsed
  }

  const setScrollList = (id, value) => {
    setState((pre) => ({
      ...pre,
      scrollList: { ...pre.scrollList, [id]: value },
    }))
  }
  const setTranscriptFontSize = (value) =>
    setState((pre) => ({ ...pre, transcriptFontSize: value }))

  //const initState =
  const [state, setState] = useState({
    item: tempItem,
    unSavedItem: JSON.parse(JSON.stringify(tempItem)),
    siblings: siblings,
    previewMode: previewMode,
    previousItem: tempPreviousItem,
    previousItems: [],
    device: props.state.device,
    tourId: props.tourId,
    changesDetected: false,
    updateItem: updateItem,
    buttonPressed: null,
    tourSectionManager: false,
    openTourSectionManager: openTourSectionManager,
    openSnapShot: false,
    openingSnapShot: openingSnapShot,
    openComponentSelector: false,
    openingComponentSelector: openingComponentSelector,
    ComponentSelectorProperties: {},
    setComponentSelector: () => {
      console.log("selecting component default selector")
    },
    handleAssetsViewerOpen: handleAssetsViewerOpen,
    handleAssetsViewerClose: handleAssetsViewerClose,
    openAssetBrower: false,
    assetRequirements: undefined,
    preSelectedAsset: undefined,
    setImg: null,
    updatePreviewMode: updatePreviewMode,
    portrait: portrait,
    updateTourPlayer: updateTourPlayer,
    TourPlayer: { width: 0, height: 0 },
    checkIfCodeIsUsed: checkIfCodeIsUsed,
    scrollList: {},
    setScrollList: setScrollList,
    transcriptFontSize: 2,
    setTranscriptFontSize: setTranscriptFontSize,
  })
  useEffect(() => {
    // console.log('update functions',state.item, state.previousItem);
    setState((prevState) => {
      return {
        ...prevState,
        siblings: siblings,
        //unSavedItem:state.unSavedItem,
        updateItem: updateItem,
        updateComponent: updateComponent,
        openTourSectionManager: openTourSectionManager,
        openingSnapShot: openingSnapShot,
        openingComponentSelector: openingComponentSelector,
        handleAssetsViewerOpen: handleAssetsViewerOpen,
        handleAssetsViewerClose: handleAssetsViewerClose,
        updateTourPlayer: updateTourPlayer,
        checkIfCodeIsUsed: checkIfCodeIsUsed,
      }
    })
    if (state.item !== undefined && state.item !== null) {
      setCommentManager(
        <CommentsManager
          key={"item" + state.item.id}
          getNumberOfIssues={(number) => {
            setNumberOfIssues(number)
            console.log("number of issues", number)
          }}
        />
      )
    }
    //setTempItem(state.item)
  }, [state.changesDetected, state.unSavedItem, state.item, state.TourPlayer])
  //console.log('component loader: create previous 2',state.item, state.previousItem);
  const mainProjectPath = `${state.item.clientId}/${state.item.projectId}`
  const updateComponent = async (comp, closePreview = true) => {
    console.log("updateComponent ==> ", comp.id, comp.data, state.device)

    //console.log('document.body:',document.getElementById('root'),htmlTest,(<div id='test'><h2>hello</h2></div>));
    // comp.tourDetails = tourDetails;
    // comp.clientId = comp.data.clientId
    // comp.projectId = comp.data.projectId
    // comp.tourId = tourDetails.id

    if (comp.data?.warnings?.length > 0) {
      setOpenWarningSave(true)
      console.log("can't save with warnings")
    } else {
      console.log("handleMenuBuilderChange 2:", comp)
      console.log(
        "check changes:",
        JSON.stringify(state.unSavedItem.data) === JSON.stringify(comp.data),
        state.unSavedItem.data,
        item.data,
        comp.data
      )
      if (
        JSON.stringify(state.unSavedItem.data) !== JSON.stringify(comp.data)
      ) {
        // new Date().toLocaleString('en-US', { timeZone: 'America/Grand_Turk' })
        //   fetch("https://world-clock.p.rapidapi.com/json/est/now", {
        //   method: "GET",
        //   headers: {
        //     "x-rapidapi-host": "world-clock.p.rapidapi.com",
        //     "x-rapidapi-key":
        //       "898fd0ed34mshaf26eb1c99d15f9p1e6482jsnddca8358c0d6",
        //   },
        // })
        //   .then((res) => res.json())
        //   .then(
        // async(result) => {
        // var currentTime = result.currentFileTime;
        var currentTime = new Date().toLocaleString("en-US", {
          timeZone: "America/Grand_Turk",
        })

        comp.data.modifiedData = currentTime.replaceAll("/", "-")
        comp.data.enableCache = false
        let postData = {
          id: comp.id,
          projectId: comp.projectId,
          clientId: comp.clientId,
          tourId: props.tourId,
          initialScreen:
            comp.tourDetails.data.initialScreen !== undefined
              ? comp.tourDetails.data.initialScreen
              : null,
          data: comp.data,
        }

        console.log("postData: ", postData)

        await Database.updateComponent(postData)
          .then((res) => {
            console.log("comp uploaded ", res)
            setState((prevState) => {
              return {
                ...prevState,
                unSavedItem: JSON.parse(JSON.stringify(comp)),
                changesDetected: false,
                //updateItem:updateItem,
                //updateComponent:updateComponent,
              }
            })
            setTempItem(comp)
            //updateItem(comp)
          })
          .catch((err) => console.log(err))
        // })

        // setState(return {
        //   ...prevState,
        //   changesDetected: true,
        // })
        // if (closePreview) {
        //   //closePreviewMode()
        // }
      }
    }
    let elementsToRender = await GetElementsToRender(comp, state.device)
    console.log(
      "Check modified and build date",
      comp.data.modifiedData,
      comp.data.buildDate,
      comp.data.modifiedData > comp.data.buildDate,
      comp
    )
    if (elementsToRender) {
      //if (elementsToRender && !checkBuild) {
      let confirmRender = window.confirm(
        "Would you like to update the render as well?"
      )
      if (confirmRender) {
        console.log("elementsToRender ==> ", elementsToRender)

        for (const [index, element] of elementsToRender.entries()) {
          // await ReactDOM.render(
          //   element.render,
          //   document.getElementById("htmltoimage")
          // )
          await setHtmlToImage(element.render)
          function timeout(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms))
          }

          await timeout(3000)
          let renderedElement = document.getElementById(element.id)
          console.log(
            "elementToRender ==> ",
            element.id,
            element,
            renderedElement
          )

          if (renderedElement) {
            let dataUrl
            if (element.format === "jpg") {
              dataUrl = await htmlToImage.toJpeg(
                document.getElementById(element.id),
                {
                  quality: 0.9,
                  // canvasWidth:element.renderSize.width/2,
                  // canvasHeight:element.renderSize.height/2,
                  canvasWidth: element.renderSize.width,
                  canvasHeight: element.renderSize.height,
                  //width:element.renderSize.width/2,
                  //height:element.renderSize.height/2,
                }
              )
            } else {
              dataUrl = await htmlToImage.toPng(
                document.getElementById(element.id),
                {
                  // canvasWidth:element.renderSize.width/2,
                  // canvasHeight:element.renderSize.height/2,

                  canvasWidth: element.renderSize.width,
                  canvasHeight: element.renderSize.height,

                  //skipAutoScale:true,
                  // canvasWidth:element.renderSize.width/2,
                  // canvasHeight:element.renderSize.height/2,
                }
              )
              //let canvas = await html2canvas(document.getElementById(element.id));
              //dataUrl = canvas.toDataURL('image/png', 1.0);
            }

            console.log("dataUrl", dataUrl)
            let mainProjectPath = `${comp.clientId}/${comp.projectId}`
            console.log("adding", comp.data.type, mainProjectPath)
            let dbResponse = await AssetDatabase.uploadDynamicAsset(
              mainProjectPath,
              dataUrl,
              comp.id + "_" + element.id + "." + element.format,
              comp
            )

            console.log("dbResponse:", dbResponse)
            if (dbResponse) {
              element.assetLocation.path_lower = dbResponse.path_lower
              element.assetLocation.name =
                comp.id + "_" + element.id + "." + element.format
            }

            await setHtmlToImage(null)
          }
        }
      }
    }
  }

  // let blob = new Blob()
  /*const uploadComponent = async(comp)=>{
    await Database.createComponent(comp).then(res=>{
      console.log('comp uploaded')
    }).catch(err=>console.log(err))
  }*/
  const handleWarningClose = async (action) => {
    if (action === "cancel") {
      setOpenWarningSave(false)
    } else {
      //console.log('handleMenuBuilderChange 2:',JSON.stringify(item.data.buttons));
      let postData = {
        id: state.item.id,
        projectId: state.item.projectId,
        clientId: state.item.clientId,
        tourId: props.tourId,
        data: state.item.data,
      }

      console.log(postData)

      await Database.updateComponent(postData)
        .then((res) => {
          //console.log('item uploaded ',JSON.stringify(item))
        })
        .catch((err) => console.log(err))
      if (typeof window !== `undefined`) {
        window.history.back()
      }
    }
  }
  //const [wind]
  const handleResize = () => {
    // const info = myRef.current.getBoundingClientRect();
    //setMyComponentWidth(info.width)
    //setMyComponentHeight((info.width/9) *16)
    console.log("resize 360: ", window.innerWidth, window.innerHeight)
  }
  window.addEventListener("resize", handleResize)

  const GREY = "#9E9E9E"

  console.log("item:", state.item)
  let history = useHistory()
  let helpLink = GettingHelpURL(state.item.data.type)
  // const value = React.useMemo(() => (state), [state.item,
  //   state.unSavedItem,
  //   state.siblings,
  //   state.previewMode,
  //   state.previousItem,
  //   state.previousItems,
  //   state.device,
  //   state.tourId,
  //   state.changesDetected,
  //   state.buttonPressed,
  //   state.tourSectionManager,
  //   state.openSnapShot,
  //   state.openComponentSelector,
  //   state.ComponentSelectorProperties,
  //   state.setComponentSelector,
  //   state.openAssetBrower,
  //   state.assetRequirements,
  //   state.preSelectedAsset,
  //   state.setImg,
  //   state.portrait,
  //   ])
  // updateItem,
  // updatePreviewMode,
  // handleAssetsViewerOpen,
  // handleAssetsViewerClose,
  // openingComponentSelector,
  // openTourSectionManager,
  // openingSnapShot,
  // updateTourPlayer,
  // TourPlayer,
  // checkIfCodeIsUsed,

  return (
    <ItemContext.Provider value={state}>
      <Grid
        container
        direction={"column"}
        style={{ width: "100%", height: "75vh" }}
      >
        <Grid
          item
          style={{
            position: "inherit",
            width: !state.previewMode ? "40%" : "90%",
            marginRight: "10%",
            height: "100%",
          }}
          justify={"center"}
          alignItems={"center"}
          // alignContent={"center"}
        >
          <Grid
            item
            style={{
              width: "inherit",
              height: "inherit",
              position: "fixed",
              marginTop: state.previewMode ? "0%" : "0%",
              marginLeft: state.previewMode ? "0%" : "0%",
            }}
          >
            {/*<ReactCursorPosition>*/}
            <TourPlayer
              key={"menu" + state.item.id + state.previewMode}
              item={state.item}
            />
            {/* </ReactCursorPosition>*/}
          </Grid>
        </Grid>
        {!state.previewMode ? (
          <Grid item style={{ width: "50%", marginLeft: "0%" }}>
            <Grid
              item
              style={{ width: "100%", textAlign: "left", display: "flex" }}
            >
              <Typography variant="h1" component="h1">
                {state.item.data.title}
              </Typography>
              {helpLink !== "" ? (
                // <a target='_blank' rel="noopener noreferrer" href={helpLink}>help</a>
                <HelpButton url={helpLink} />
              ) : null}
            </Grid>
            <Grid
              style={{
                width: "100%",
                marginBottom: 200,
                paddingBottom: 200,
              }}
            >
              <ComponentSettings key={state.item.id} />
              <Grid style={{ height: 100 }}></Grid>
            </Grid>
          </Grid>
        ) : null}
        <TourSectionManager
          section={state.item.data.section}
          sections={state.item.allSections}
          settings={{
            id: state.item.id,
            projectId: state.item.projectId,
            clientId: state.item.clientId,
            tourId: props.tourId,
            data: state.item.data,
          }}
          open={state.tourSectionManager}
          onClose={() => {
            state.openTourSectionManager(false, state.previewMode)
          }}
        />

        <Grid
          container
          justify="space-between"
          style={{
            backgroundColor: "#444",
            width: "100%",
            height: 60,
            left: "0",
            position: "fixed",
            bottom: 0,
            zIndex: 201,
          }}
        >
          <Grid item>
            <Grid
              container
              onClick={() => {
                setOpenExplore(!openExplore)
              }}
              style={{
                border: "2px solid #ccc",
                backgroundColor: "#383838",
                borderRadius: 3,
                minWidth: 250,
                height: 60,
                cursor: "pointer",
              }}
              justify="center"
              alignItems="center"
            >
              <h3 style={{ color: "white" }}>{state.item.data.title}</h3>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              style={{ width: 200, height: 60 }}
              justify="space-between"
              alignItems="center"
            >
              <Grid item className="bottomButtonsforAcrtions">
                {!UserPermissions.checkPermissions(UserPermissions.EditTour) ? (
                  <VisibilityIcon
                    onClick={() => {}}
                    style={{
                      color: state.previewMode ? "#b9d949" : "white",
                      width: "30",
                      height: "30",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <VisibilityIcon
                    onClick={() => {
                      updateComponent(state.item, false)
                      state.previewMode
                        ? state.updatePreviewMode(false)
                        : state.updatePreviewMode(true)
                    }}
                    style={{
                      color: state.previewMode ? "#b9d949" : "white",
                      width: "30",
                      height: "30",
                      cursor: "pointer",
                    }}
                  />
                )}
                {/* <VisibilityIcon
                  onClick={() => {
                    // console.log('UserPermissions.checkPermissions(UserPermissions.EditTour) :>> ', UserPermissions.checkPermissions(UserPermissions.EditTour));
                    // if (UserPermissions.checkPermissions(UserPermissions.EditTour)) {
                    updateComponent(state.item, false)
                    state.previewMode ? state.updatePreviewMode(false) : state.updatePreviewMode(true)
                    // }

                  }}
                  style={{
                    color: state.previewMode ? "#b9d949" : "white",
                    width: "30",
                    height: "30",
                    cursor: "pointer",
                  }}
                /> */}
              </Grid>
              {!UserPermissions.checkPermissions(
                UserPermissions.EditTour
              ) ? null : (
                <Grid item>
                  <BuildIcon
                    onClick={() => {
                      state.previewMode
                        ? state.updatePreviewMode(false)
                        : state.updatePreviewMode(true)
                    }}
                    style={{
                      color: !state.previewMode ? "#b9d949" : "white",
                      width: "30",
                      height: "30",
                      cursor: "pointer",
                    }}
                  />
                </Grid>
              )}
              <Grid item>
                <Grid container direction="row">
                  <Grid item>
                    <ChatBubbleOutlineIcon
                      style={{
                        color: openSidePanel
                          ? "#b9d949"
                          : numberOfIssues === 0
                          ? "white"
                          : "#F5A623",
                        width: "30",
                        height: "30",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setOpenSidePanel(!openSidePanel)
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    style={{
                      backgroundColor: "#F5A623",
                      color: "white",
                      width: 20,
                      height: 20,
                      borderRadius: "50%",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    {numberOfIssues}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <NoteAdd
                  style={{
                    color: openNotesPanel ? "#b9d949" : "white",
                    width: "30",
                    height: "30",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setOpenNotesPanel(!openNotesPanel)
                  }}
                />
              </Grid>
              {/* <Grid item>
          <WarningIcon style={{color:openSidePanel?'#b9d949':'white', width:'30', height:'30',cursor:'pointer'}} onClick={()=>{setOpenSidePanel(!openSidePanel)}}/>
          </Grid> */}
            </Grid>
          </Grid>
          <Grid item style={{ minWidth: 200 }}>
            {!state.previewMode ? (
              <React.Fragment>
                {item.data?.type === "keypad" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={(event) => {
                      if (props.openKeypadSettings) {
                        window.history.back()
                      } else {
                        closePreviewMode()
                      }
                    }}
                  >
                    Close Keypad
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => {
                      if (state.changesDetected) {
                        if (
                          window.confirm(
                            "It looks like you have made chages that will not be saved when closing, are you sure you want to close"
                          )
                        ) {
                          console.log(
                            "closing with unsaved work",
                            state.item,
                            state.unSavedItem
                          )
                          Object.assign(state.item, state.unSavedItem)
                          console.log("closing with unsaved work 2", state.item)
                          updateItem(state.item)
                          closePreviewMode()
                        }
                      } else {
                        closePreviewMode()
                      }
                    }}
                  >
                    Close
                  </Button>
                )}
                {item.data?.type === "keypad" ? (
                  <Button
                    variant="contained"
                    disabled={!state.changesDetected}
                    // disabled ={!state.changesDetected}
                    color="primary"
                    className={classes.button}
                    onClick={(event) => {
                      // updateComponent(state.item)
                      if (props.openKeypadSettings) {
                        window.history.back()
                      } else {
                        closePreviewMode()
                      }
                    }}
                  >
                    Save Keypad Changes
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    disabled={!state.changesDetected}
                    // disabled ={!state.changesDetected}
                    color="primary"
                    className={classes.button}
                    onClick={() => {
                      updateComponent(state.item)
                    }}
                  >
                    Save
                  </Button>
                )}
                {state.item.data.type !== "keypad" ? (
                  <SnapShotManager
                    open={state.openSnapShot}
                    onClose={handleSnapShotClose}
                  />
                ) : null}
                <ComponentSelector
                  open={state.openComponentSelector}
                  onClose={handleComponentSelectorClose}
                  componentSelectorProperties={
                    state.ComponentSelectorProperties
                  }
                  items={siblings}
                  sections={state.item.allSections}
                />
                <WarningComponentPopup
                  open={openWarningSave}
                  onClose={handleWarningClose}
                />
                <ComponentNotes
                  open={openNotesPanel}
                  onClose={() => {
                    setOpenNotesPanel(false)
                  }}
                />
                <AssetsViewer
                  handleClose={state.handleAssetsViewerClose}
                  open={state.openAssetBrower}
                  mainProjectPath={mainProjectPath}
                  componentInfo={state.item}
                  preSelectedAsset={state.preSelectedAsset}
                  assetRequirements={state.assetRequirements}
                  setBgImg={state.setImg}
                  isTopLevel={false}
                />
              </React.Fragment>
            ) : (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => {
                  closePreviewMode()
                }}
              >
                Close
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Slide direction="left" in={openSidePanel}>
        <Grid
          container
          style={{
            backgroundColor: "#eeeeee",
            boxShadow: `10px 30px 10px ${GREY}`,
            position: "fixed",
            width: 450,
            height: window.innerHeight - 160,
            right: 0,
            top: 100,
            zIndex: 99999999,
          }}
        >
          {commentManager}
        </Grid>
      </Slide>

      <Slide direction="up" in={openExplore} mountOnEnter unmountOnExit>
        <Grid
          container
          justify="space-between"
          style={{
            backgroundColor: "grey",
            position: "fixed",
            zIndex: 200,
            width: "100%",
            height: 230,
            padding: 0,
            left: 0,
            bottom: 60,
          }}
        >
          {/* <ComponentPopupList
            onSelection={() => {
              setOpenExplore(false)
            }}
          /> */}
          <ComponentPopupList
            onSelection={() => {
              setOpenExplore(false)
            }}
          />
        </Grid>
      </Slide>
      {/* <Grid style={{backgroundColor:'white', position:'absolute', top:0,left:0}}> */}
      {htmlToImageDiv}
      {/* </Grid> */}

      <Dialog
        open={alertOpen}
        maxWidth={"1200px"}
        onClose={() => setAlertOpen(false)}
      >
        <DialogTitle>Warning !</DialogTitle>
        <DialogContent>
          <p>
            You have incorrect values in below Slices, kindly fix them before
            creating/updating the component.
          </p>

          {warnings.map((item) => {
            console.log("item", item)
            return (
              <div style={{ marginBottom: "20px" }}>
                <h5 style={{ marginBottom: "5px" }}>{item.title}</h5>
                <p>- {item.desc}</p>
              </div>
            )
          })}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAlertOpen(false)
            }}
            color="primary"
            autoFocus
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </ItemContext.Provider>
  )
}

export default ComponentLoader

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import Grid from "@material-ui/core/Grid"

import TourPlayer from "../tourComponents/tourPlayer/TourPlayer"
import { ItemContext } from "../tourComponents/ComponentLoader"
import { useHistory } from "react-router-dom"

import DefaultSettings from "../../defaultSettings/settings"
import iNoBounce from "inobounce"

import Analytics from "../../config/anayltics"
import { isIOS, isSafari } from "react-device-detect"
import { v4 as uuidv4 } from "uuid"

// const DialogContent = withStyles((theme) => ({
//   root: {},
// }))(MuiDialogContent)

// const tableIcons = {
//   Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
//   Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
//   Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
//   Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
//   DetailPanel: forwardRef((props, ref) => (
//     <ChevronRight {...props} ref={ref} />
//   )),
//   Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
//   Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
//   Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
//   FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
//   LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
//   NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
//   PreviousPage: forwardRef((props, ref) => (
//     <ChevronLeft {...props} ref={ref} />
//   )),
//   ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
//   Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
//   SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
//   ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
//   ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
// }
export default function ComponentPreview(props) {
  //let [items, setItems] = React.useState(props.items);
  let { item } = props
  const [tempPreviousItem, setTempPreviousItem] = React.useState(null)
  //let imagePath = `${item.data.assets.imageBackground}`;
  //let image = null;
  let tempItem = { ...item, clientId: item.clientId, projectId: item.projectId }
  tempItem.data.tourId = item.data.tourId
  let portrait = true

  if (item.data.orientation !== undefined) {
    if (item.data.orientation === "Landscape") {
      portrait = false
    }
  }
  let tempWidth = 0
  let tempHeight = 0

  const updateTourPlayer = (width, height) => {
    console.log(
      "updateTourPlayer: ",
      state.TourPlayer.width,
      state.TourPlayer.height,
      width,
      height
    )
    if (tempWidth !== width || tempHeight !== height) {
      tempHeight = height
      tempWidth = width
      setState((prevState) => {
        return {
          ...prevState,
          TourPlayer: { width: width, height: height },
          updateTourPlayer: updateTourPlayer,
        }
      })
    }
  }
  let history = useHistory()

  useEffect(() => {
    console.log("item.id in ComponentPreview :>> ", item.id, item.data, item)
    const { title, type, section, reference, code, orientation } = item.data
    setState((pre) => ({
      ...pre,
      portrait: orientation === "Landscape" ? false : true,
    }))
    const getData = async () => {
      // const iNoBounce = await import("inobounce");
      let today = new Date().toString()
      // props.setDownloadStartLang(today)

      const app_namecustom =
        item.projectDetails.data.clientName === "Historic Environment Scotland"
          ? "HES"
          : item.projectDetails.data.clientName

      if (
        localStorage &&
        localStorage.getItem("TBP-userID") &&
        localStorage.getItem("TBP-session-id")
      ) {
        const userID = localStorage.getItem("TBP-userID")
        const session_id = localStorage.getItem("TBP-session-id")

        Analytics.eventTrigger("single_page_view", {
          // app_name: item.projectDetails.data.clientName + "_webapp",
          app_name: app_namecustom + "_webapp",
          current_project_name: item.projectDetails.data.projectName,
          current_tour_name: item.tourDetails.data.tourName,
          tour_name: item.tourDetails.data.tourName,
          tour_id: item.data.tourId,
          download_start_time: today,
          current_section: section,
          current_page_title: title,
          current_content_type: type,
          current_reference_id: reference,
          keypad_code: code,
          user_id: userID,
          userID: userID,
          session_id: session_id,
        })
      } else {
        const session_id = uuidv4()
        localStorage.setItem("TBP-session-id", session_id)

        Analytics.eventTrigger("single_page_view", {
          // app_name: item.projectDetails.data.clientName + "_webapp",
          app_name: app_namecustom + "_webapp",
          current_project_name: item.projectDetails.data.projectName,
          current_tour_name: item.tourDetails.data.tourName,
          tour_name: item.tourDetails.data.tourName,
          tour_id: item.data.tourId,
          download_start_time: today,
          current_section: section,
          current_page_title: title,
          current_content_type: type,
          current_reference_id: reference,
          keypad_code: code,
          session_id: session_id,
        })
      }

      if (item.data.type === "EnhancedGoogleMap" && (isIOS || isSafari)) {
        console.log("begin")

        iNoBounce.enable()
        console.log("end")
        console.log(
          "called inside useEffect checking the item id :>> ",
          item.id
        )
      } else {
        console.log("begin else block")
        iNoBounce.disable()
        console.log("ending else block")
      }
    }
    getData()
  }, [item.id])

  const updateItem = (item, from, button = null, currentItem = null) => {
    console.log("ComponentPreview item TOP LEVEL: ", item, from, button)
    console.log(
      "update item from component loader preview: ",
      item,
      from,
      "button: ",
      button
    )
    if (item === null) {
      console.log("ComponentPreview item null: ", item)
      if (DefaultSettings.displayMode === "CMS") {
        window.alert(
          "There is no previous component defined",
          JSON.stringify(button)
        )
      }
      return
    } else if (item === "exit") {
      console.log("ComponentPreview item exit: ", item)
      if (props.exitTour !== undefined) {
        props.exitTour()
      } else {
        window.alert("This button will trigger the device to exit the tour")
      }
      return
    } else if (item === "externalLink") {
      if (
        window.confirm(
          "This button will trigger the device to exit the tour to an external link"
        )
      ) {
        window.location.href = `${button.externalLink}`
      }

      return
    } else if (item === "externalLinkWithoutButton") {
      // console.log('button, item and Item type :>> ', button, item, currentItem.externalLink);
      // window.open(`${currentItem.externalLink}`)
      if (
        window.confirm(
          "This button will trigger the device to exit the tour to an external link"
        )
      ) {
        // window.location.href = `${currentItem.externalLink}`;
        window.open(`${currentItem.externalLink}`)
      }
      return
    }

    console.log("ComponentPreview item AFTER ITEM CHECK: ", item, from, button)
    const previousItem = state.item
    let portrait = true
    if (item !== null && item !== undefined) {
      if (item.data.orientation !== undefined) {
        if (item.data.orientation === "Landscape") {
          portrait = false
        }
      }
    }

    if (state.item !== item) {
      console.log("ComponentPreview item AFTER PORTRAIT: ", item, from, button)
      if (state.previousItems.length > 1) {
        //newPrevious = state.previousItems[state.previousItems.length-2];
        console.log(
          "update going back to ",
          previousItem,
          item,
          state.previousItems[-1] === item,
          state.previousItems.lastIndexOf(item),
          state.previousItems
        )
      }
      console.log(
        "ComponentPreview item BEFORE PREVIOUS INDEX: ",
        item,
        from,
        button
      )
      let previousIndex = state.previousItems.lastIndexOf(item)
      console.log("previousIndex: ", previousIndex)
      if (previousIndex >= 0) {
        let newPrevious = null
        console.log("ComponentPreview item PREVIOUS INDEX: ", item)
        console.log("going back to ", previousItem, state.previousItems.length)
        if (state.previousItems.length > 0) {
          newPrevious = state.previousItems[previousIndex - 1]
          console.log("going back to new", newPrevious)
        }
        console.log("ComponentPreview item: ", item)
        setState((prevState) => {
          return {
            ...prevState,
            item: item,
            portrait: portrait,
            previousItem: newPrevious,
            previousItems: state.previousItems.splice(
              previousIndex,
              state.previousItems.length - previousIndex
            ),
            buttonPressed: button,
          }
        })
      } else {
        console.log("ComponentPreview item (else): ", item)
        state.previousItems.push(previousItem)
        setState((prevState) => {
          return {
            ...prevState,
            previousItem: previousItem,
            previousItems: state.previousItems,
            item: item,
            portrait: portrait,
            buttonPressed: button,
          }
        })
      }

      console.log("ComponentPreview item AFTER INDEX: ", item, from, button)

      if (item.data !== undefined) {
        history.push(item.data.tourId + "_" + item.id)
      } else {
        history.push("0")
      }
    } else {
      setState((prevState) => {
        return {
          ...prevState,
          item: item,
          //portrait: portrait,
          buttonPressed: button,
        }
      })
    }
  }
  /*const updateItem = (item, previewMode, from) => {
          //console.log('update item from component loader preview: ',item, state.previewMode);
          if(item === null){
            window.alert("There is no previous component defined");
            return
          }
          const previousItem = state.item;
          let portrait = true;
      
          if(item.data.orientation!== undefined){
            if(item.data.orientation === 'Landscape'){
              portrait = false;
            }
          }
          /*if(){

          }*/
  /* if(previousItem === item){
            
            setState(prevState => {
              return {...prevState,
                item: item, portrait:portrait
              }
            })
          }
          else{
            setState(prevState => {
              return {...prevState,
                previousItem:previousItem, item: item, portrait:portrait
              }
            })
          }
          
        }*/

  const setScrollList = (id, value) => {
    setState((pre) => ({
      ...pre,
      scrollList: { ...pre.scrollList, [id]: value },
    }))
  }
  const setTranscriptFontSize = (value) =>
    setState((pre) => ({ ...pre, transcriptFontSize: value }))

  const [state, setState] = React.useState({
    item: tempItem,
    siblings: props.siblings,
    previewMode: true,
    snapshot: false,
    device: props.device,
    updateItem: updateItem,
    tourSectionManager: false,
    openTourSectionManager: () => {},
    openSnapShot: false,
    openingSnapShot: () => {},
    updatePreviewMode: () => {},
    handleAssetsViewerOpen: () => {},
    handleAssetsViewerClose: () => {},
    openAssetBrower: false,
    assetRequirements: undefined,
    preSelectedAsset: undefined,
    setImg: null,
    updatePreviewMode: () => {},
    portrait: portrait,
    updateTourPlayer: updateTourPlayer,
    TourPlayer: { width: 0, height: 0 },
    item: tempItem,
    previousItem: tempPreviousItem,
    previousItems: [],
    openComponentSelector: false,
    openingComponentSelector: () => {},
    ComponentSelectorProperties: {},
    viewable: props.viewable,
    showPortrait: props.showPortrait,
    setComponentSelector: () => {
      console.log("selecting component default selector")
    },
    buttonPressed: null,
    scrollList: {},
    setScrollList: setScrollList,
    transcriptFontSize: 2,
    setTranscriptFontSize: setTranscriptFontSize,
  })
  useEffect(() => {
    // console.log('update functions',state.item, state.previousItem);
    if (state.previousItem === null) {
      setType(state.item.id)
    } else {
      if (
        state.item.data.type === "AudioImage" &&
        state.previousItem.data.type === "AudioImage"
      ) {
      } else if (
        state.item.data.type === "Video" &&
        state.previousItem.data.type === "Video"
      ) {
      }
      // else if(state.item.data.type === 'Dynamic' && state.previousItem.data.type === 'Dynamic'){

      // }
      else {
        setType(state.item.id)
      }
    }
    setState((prevState) => {
      return {
        ...prevState,
        updateTourPlayer: updateTourPlayer,
        updateItem: updateItem,
        showPortrait: props.showPortrait,
        viewable: props.viewable,
      }
    })
    //setTempItem(state.item)
  }, [state.item, props.showPortrait, props.viewable])
  useEffect(() => {
    // console.log('update functions',state.item, state.previousItem);
    setState((prevState) => {
      return {
        ...prevState,
        item: props.item,
      }
    })
    //setTempItem(state.item)
  }, [props.item])
  console.log("update functions", state.item, state.buttonPressed)

  console.log("portrait", portrait)

  //setTempItem(state.item)
  const [type, setType] = React.useState("")
  const containerRef = React.useRef(null)

  return (
    <Grid
      container
      style={{ marginLeft: "25%", width: "50%", height: "100%" }}
      justify="center"
      ref={containerRef}
    >
      <ItemContext.Provider value={state}>
        <TourPlayer key={"menu" + type} />
        {/* <Dialog fullScreen onClose={()=>{}} aria-labelledby="customized-dialog-title" open={!props.showPortrait}>
        <DialogContent style={{width:'100%', height:'100%', padding:0,margin:0}}>
              <Grid container style={{width:'100%', height:'100%', backgroundColor:'white'}} justify={'center'} alignContent={'center'}>
                  

                  <img src={require("../images/Rotate_Device.png")} style={{width:'50%', height:'50%',marginTop:'10%', objectFit:'contain'}}/>
            </Grid>
            
          </DialogContent>
      </Dialog> */}
      </ItemContext.Provider>
    </Grid>
  )
}
